const tabs = () => {
	const tabSections = document.querySelectorAll('.tabs');

	tabSections.forEach((tabSection) => {
		const tabNavs = tabSection.querySelectorAll('.tabs-nav');
		const inputTrigger = tabSection.querySelector('.tabs-nav-mobile .tabs-nav');

		tabNavs.forEach((tabNav) => {
			const tabNavItems = tabNav.querySelectorAll('li');

			tabNavItems.forEach((tabNavItem, index) => {
				tabNavItem.addEventListener('click', (e) => {
					e.preventDefault();

					console.log('tabNavItem', tabNavItem);

					let elTarget = e.currentTarget.dataset.tab;

					tabSection
						.querySelectorAll('.tab[data-active]')
						.forEach((el) => delete el.dataset.active);

					tabSection
						.querySelectorAll('.tabs-nav li[data-active]')
						.forEach((el) => delete el.dataset.active);

					e.currentTarget.dataset.active = true;
					tabSection
						.querySelectorAll(`.tab.${elTarget}`)
						.forEach((tab) => (tab.dataset.active = true));
				});
			});
		});

		inputTrigger &&
			inputTrigger.addEventListener('change', (e) => {
				e.preventDefault();

				let elTarget = e.currentTarget.value;

				tabSection
					.querySelectorAll('.tab[data-active]')
					.forEach((el) => delete el.dataset.active);

				tabSection
					.querySelectorAll('.tabs-nav li[data-active]')
					.forEach((el) => delete el.dataset.active);

				//trigger.dataset.active = true;
				tabSection
					.querySelectorAll(`.tab.${elTarget}`)
					.forEach((tab) => (tab.dataset.active = true));
			});
	});
};

export default tabs;
